import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { withRouter } from "react-router";
import dayjs from "dayjs";
import 'dayjs/locale/pt-br';
import { Divider, DateRangePicker, Button, CheckPicker, InputNumber, Notification, RadioGroup, FormGroup, Radio, SelectPicker, Icon } from "rsuite";
import axios from "../../utilities/axios";

import InfractionCard from "./components/Card/InfractionCard";
import LineChartPerWeek from "./components/LineChartPerWeek/lineChartPerWeek";
import EmployeeErrorPerWeekChart from "./components/EmployeeErrorPerWeekChart/employeeErrorPerWeekChart";
import LineChartPerMonth from "./components/LineChartPerMonth/lineChartPerMonth";
import { PieChartPerPeriodWeek } from "./components/PieChartPerPeriodWeek/pieChartPerPeriodWeek";
import { DetailsStorePieChart } from "./components/DetailsStorePieChart/detailsStorePieChart";
import EmployeeErrorPerMonthChart from "./components/EmployeeErrorPerMonthChart/employeeErrorPerMonthChart";
import ErrorPerEmployeeDateChart from "./components/ErrorPerEmployeeDateChart/errorPerEmployeeDateChart";

dayjs.locale('pt-br');
interface GraphsAnalyticsPageState {
    filters: any;
    data: any;
    showDetailsPieChart: boolean;
    showDetailsLineChart: boolean;
    loadingButton: boolean;
    hiddenGeneralFilters: boolean;
    hiddenMensalChartFilters: boolean;
}

class GraphsAnalyticsPage extends React.Component<any, GraphsAnalyticsPageState> {
    private pieChartRef: React.RefObject<any>;
    private detailsStorePieChartRef: React.RefObject<any>;

    constructor(props: any) {
        super(props);
        this.pieChartRef = React.createRef();
        this.detailsStorePieChartRef = React.createRef()
        this.state = {
            filters: {
                violationDateRange: [
                    dayjs().startOf('month').toDate(),
                    dayjs().endOf('month').toDate(),
                ],
                responsibleEmployee: null,
                author: null,
                platform: null,
                store: null,
                errorType: null,
                associatedOrder: 0,
                type: "period",
                selectedStore: null
            },
            data: {
                employeeData: [],
                authorsData: [],
                platformData: [],
                storeData: [],
                errorTypeData: [],
                orderData: [],
                selectFullData: []
            },
            loadingButton: false,
            showDetailsPieChart: false,
            showDetailsLineChart: false,
            hiddenGeneralFilters: false,
            hiddenMensalChartFilters: false
        };
        this.pieChartRef = React.createRef();
        this.detailsStorePieChartRef = React.createRef();
    }

    async componentDidMount(): Promise<void> {
        await this.handleGetInputInformation()
        await this.handleGetGraphsInformation()
    }

    handleSetLoadingTrue = () => {
        this.setState({
            ...this.state,
            loadingButton: true
        })
    }

    handleSetLoadingFalse = () => {
        this.setState({
            ...this.state,
            loadingButton: false
        })
    }

    handleGetInputInformation = async () => {
        try {
            const response = await axios.get("/api/v1/dashboard/get_informations");
            if (response?.data) {
                const formattedUsers = Array.isArray(response.data.users)
                    ? response.data.users.map((user: any) => ({
                        value: user?.user_id || '',
                        label: user?.user_name || 'Unknown User',
                    }))
                    : [];

                const formattedAuthors = Array.isArray(response.data.authors)
                    ? response.data.authors.map((author: any) => ({
                        value: author?.user_id || '',
                        label: author?.user_name || 'Unknown Author',
                    }))
                    : [];

                const formattedPlatforms = Array.isArray(response.data.platforms)
                    ? response.data.platforms.map((platform: any) => ({
                        value: platform?.platform_id || '',
                        label: platform?.platform_name || 'Unknown Platform',
                    }))
                    : [];

                const formattedStores = Array.isArray(response.data.stores)
                    ? response.data.stores.map((store: any) => ({
                        value: store?.store_id || '',
                        label: store?.store_name || 'Unknown Store',
                    }))
                    : [];

                const formattedErrors = Array.isArray(response.data.errors)
                    ? response.data.errors.map((error: any) => ({
                        value: error?.error_id || '',
                        label: error?.error_name || 'Unknown Error',
                    }))
                    : [];

                this.setState({
                    data: {
                        employeeData: formattedUsers,
                        authorsData: formattedAuthors,
                        platformData: formattedPlatforms,
                        storeData: formattedStores,
                        errorTypeData: formattedErrors,
                    },
                });

                if (formattedStores && formattedStores.length > 0) {
                    this.setState({
                        filters: {
                            ...this.state.filters,
                            selectedStore: formattedStores[0].value
                        }
                    })
                }
            } else {
                this.setState({
                    data: {
                        employeeData: [],
                        authorsData: [],
                        platformData: [],
                        storeData: [],
                        errorTypeData: [],
                    },
                });
            }
        } catch (error) {
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar as informações dos filtros",
                duration: 3500
            })

            console.log("Erro ao buscar as informações para os selects ::::>> ", error)
        }
    }

    handleGetGraphsInformation = async () => {
        this.handleSetLoadingTrue()

        try {
            if (this.pieChartRef.current) {
                await this.pieChartRef.current.handleGetChartData();
            }

            if (this.detailsStorePieChartRef.current) {
                await this.detailsStorePieChartRef.current.handleGetChartData()
            }
        } catch (error) {
            console.error("Erro ao atualizar o gráfico:", error);
        }

        this.handleSetLoadingFalse()
    }

    handleFilterChange = (key: string, value: any) => {
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    [key]: value,
                },
            }),
            async () => {
                if (key === "store") {
                    const { storeData } = this.state.data;
                    const selectedItems = storeData.filter((item: any) => value.includes(item.value))
                    const selectedLabels = selectedItems.map((item: any) => item.label)
                    await this.detailsStorePieChartRef.current.applyPieChartFilter(selectedLabels);
                }
            }
        );
    };

    clearFilters = async () => {
        this.handleSetLoadingTrue();

        await new Promise((resolve) => {
            this.setState(
                {
                    filters: {
                        violationDateRange: [
                            dayjs().startOf('month').toDate(),
                            dayjs().endOf('month').toDate(),
                        ],
                        responsibleEmployee: null,
                        author: null,
                        platform: null,
                        store: null,
                        errorType: null,
                        associatedOrder: 0,
                        type: "period",
                        selectedStore: this.state.data.storeData[0]?.value
                    },
                },
                //@ts-ignore
                resolve
            );
        });

        await this.handleGetGraphsInformation();
        this.handleSetLoadingFalse();
    };

    handleShowGeneralFilters = () => {
        this.setState({
            hiddenGeneralFilters: !this.state.hiddenGeneralFilters
        })
    }

    handleShowMensalChartFilters = () => {
        this.setState({
            hiddenMensalChartFilters: !this.state.hiddenMensalChartFilters
        })
    }

    render() {
        return (
            <>
                <div style={{ marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }} className={classNames("container")}>
                    <div className={styles.mainContainer}>
                        <div className={styles.containerCards}>
                            <InfractionCard />
                        </div>

                        <div className={styles.dropdownDivider}>
                            {this.state.hiddenGeneralFilters ? (
                                <>
                                    <div style={{ width: '100%', marginTop: '3rem' }}>

                                    </div>
                                    <Icon icon='up' className={styles.dropdownIcon} onClick={this.handleShowGeneralFilters} />
                                </>
                            ) : (
                                <>
                                    <Divider style={{ width: '100%' }}>Filtros Gerais</Divider>
                                    <Icon icon='down' className={styles.dropdownIcon} onClick={this.handleShowGeneralFilters} />
                                </>
                            )}
                        </div>

                        {!this.state.hiddenGeneralFilters && (
                            <div className={styles.containerFilters}>
                                <div className={styles.filterLine}>
                                    <div className={styles.filterDiv} style={{ width: '500px' }}>
                                        <span>Período</span>
                                        <DateRangePicker
                                            placeholder="Selecione uma data"
                                            format="DD/MM/YYYY"
                                            ranges={[]}
                                            onChange={(value) => this.handleFilterChange('violationDateRange', value)}
                                            value={this.state.filters.violationDateRange}
                                        />
                                    </div>

                                    <div className={styles.filterDiv} style={{ width: '320px' }}>
                                        <span>Pedido</span>
                                        <InputNumber
                                            value={this.state.filters.associatedOrder}
                                            onChange={(value) => this.handleFilterChange('associatedOrder', value)}
                                        />
                                    </div>

                                    <div className={styles.filterDiv}>
                                        <span>Autor</span>
                                        <CheckPicker
                                            data={this.state.data.authorsData}
                                            placeholder="Selecione um autor"
                                            onChange={(value) => this.handleFilterChange('author', value)}
                                            value={this.state.filters.author}
                                        />
                                    </div>

                                    <div className={styles.filterDiv}>
                                        <span>‎ </span>
                                        <div className={styles.filterButtons}>
                                            <Button
                                                appearance="primary"
                                                color="blue"
                                                style={{ width: '100%' }}
                                                onClick={this.handleGetGraphsInformation}
                                                loading={this.state.loadingButton}
                                            >
                                                Filtrar
                                            </Button>

                                            <Button
                                                appearance="primary"
                                                color="orange"
                                                style={{ width: '100%' }}
                                                onClick={this.clearFilters}
                                                loading={this.state.loadingButton}
                                            >
                                                Limpar Filtros
                                            </Button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!this.state.hiddenGeneralFilters && (
                            <Divider>Gráficos</Divider>
                        )}

                        <div className={styles.chartsContainer}>
                            <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                    <div className={styles.filters}>
                                        <div className={styles.filterDiv}>
                                            <span>Loja</span>
                                            <SelectPicker
                                                data={this.state.data.storeData}
                                                value={this.state.filters.selectedStore}
                                                placeholder="Selecione uma loja..."
                                                locale={{
                                                    noResultsText: "Nenhuma loja encontrada"
                                                }}
                                                onChange={(value) =>
                                                    this.setState((prevState) => ({
                                                        filters: {
                                                            ...prevState.filters,
                                                            selectedStore: value,
                                                        },
                                                    }))
                                                }
                                            />
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <FormGroup controlId="radioList">
                                                <span>‎ </span>
                                                <RadioGroup
                                                    name="radioList"
                                                    inline
                                                    style={{ display: 'flex' }}
                                                    value={this.state.filters.type}
                                                    onChange={(value) =>
                                                        this.setState((prevState) => ({
                                                            filters: {
                                                                ...prevState.filters,
                                                                type: value,
                                                            },
                                                        }))
                                                    }
                                                >
                                                    <Radio value="period">Período</Radio>
                                                    <Radio value="weekDays">Dias da Semana</Radio>
                                                </RadioGroup>
                                            </FormGroup>
                                        </div>
                                    </div>

                                    <PieChartPerPeriodWeek
                                        ref={this.pieChartRef}
                                        associatedOrder={this.state.filters.associatedOrder}
                                        author={this.state.filters.author}
                                        selectedStore={this.state.filters.selectedStore}
                                        violationDateRange={this.state.filters.violationDateRange}
                                        type={this.state.filters.type}
                                    />

                                </div>

                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                    <div className={styles.filterDiv}>
                                        <span>Loja</span>
                                        <CheckPicker
                                            data={this.state.data.storeData}
                                            placeholder="Selecione uma Loja"
                                            onChange={(value) => this.handleFilterChange('store', value)}
                                            value={this.state.filters.store}
                                        />
                                    </div>

                                    <DetailsStorePieChart
                                        ref={this.detailsStorePieChartRef}
                                        associatedOrder={this.state.filters.associatedOrder}
                                        author={this.state.filters.author}
                                        platform={this.state.filters.platform}
                                        responsibleEmployee={this.state.filters.responsibleEmployee}
                                        store={this.state.filters.store}
                                        violationDateRange={this.state.filters.violationDateRange}
                                    />

                                </div>
                            </div>

                            {/* <Divider>Gráfico de erro detalhado por funcionário</Divider>

                            <ErrorPerEmployeeDateChart
                                employees={this.state.data.employeeData}
                            /> */}

                            <Divider>Gráfico Semanal</Divider>

                            <EmployeeErrorPerWeekChart
                                employees={this.state.data.employeeData}
                            />

                            <LineChartPerWeek
                                stores={this.state.data.storeData}
                                platforms={this.state.data.platformData}
                            />

                            <div className={styles.dropdownDivider}>
                                {this.state.hiddenMensalChartFilters ? (
                                    <>
                                        <div style={{ width: '100%', marginTop: '3rem' }}>

                                        </div>
                                        <Icon icon='up' className={styles.dropdownIcon} onClick={this.handleShowMensalChartFilters} />
                                    </>
                                ) : (
                                    <>
                                        <Divider style={{ width: '100%' }}>Gráfico Mensal</Divider>
                                        <Icon icon='down' className={styles.dropdownIcon} onClick={this.handleShowMensalChartFilters} />
                                    </>
                                )}
                            </div>

                            <LineChartPerMonth
                                showFilters={this.state.hiddenMensalChartFilters}
                                stores={this.state.data.storeData}
                                platforms={this.state.data.platformData}
                            />

                            <EmployeeErrorPerMonthChart
                                employee={this.state.data.employeeData}
                            />

                        </div>
                    </div>
                </div >
            </>
        )
    }
}

export default withRouter(GraphsAnalyticsPage);