import React from "react";
import "./style/App.css";
import "./style/rsuite.css";
import Topbar from "./components/topbar/main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { AuthContext, ModuleContext, UserContext } from "./components/authProvider/main";
import DashboardRoutes from "./pages/routes/DashboardRoutes";
import Sidebar from "./components/sidebar/main";
import axios from "./utilities/axios";
import ProtectedRoute from "./components/protectedRoute/main";
import { Button, Modal, Notification } from "rsuite";
import { onError } from "./utilities/errorHandler";
import { PrettyError } from "./components/pretty-error/main";
import { io } from "./utilities/io";
import SocketIoEvents from "./components/socketIoEvents/SocketIoEvents";
import SetPasswordPage from "./pages/SetPasswordPage";
import ResetPassword from "./pages/ResetPassword";
import AdmissionRoutes from "./pages/routes/processAdmission/AdmissionRotes";
import ProtectedRouterAdmission from "./pages/routes/processAdmission/ProtectedRouterAdmission";
import ChecklistRoutes from "./pages/routes/ChecklistRoutes";


export class App extends React.Component<any, {}> {
    public state: any = {
        hasError: false,
        errorMessage: '',
        hiddenSidebar: localStorage.getItem("hiddenSidebar") == "true" ? true : false,
        auth: {
            token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
            isAuthenticated: localStorage.getItem("token") ? true : false,
            setAuth: (token: string | null) => {
                if (token == null) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("modules");
                    this.setState((old: any) => {
                        var newModule = { ...old.auth };
                        newModule.token = token;
                        newModule.isAuthenticated = false;
                        return { auth: newModule };
                    }, () => {
                        this.state.user.setUser(null);
                    });
                    // this.setState({ auth: { token, isAuthenticated: false } });
                } else {
                    localStorage.setItem("token", token);
                    this.setState((old: any) => {
                        var newModule = { ...old.auth };
                        newModule.token = token;
                        newModule.isAuthenticated = true;
                        return { auth: newModule };
                    }, () => {
                        axios.get('/api/v1/me').then((res) => {
                            if (res.data && res.data.user) {
                                localStorage.setItem('modules', JSON.stringify(res.data.user.modulesReturn))
                                this.state.user.setUser(res.data.user);
                            }
                        }).catch((e) => {
                            this.state.user.setUser(null);
                        })
                    });
                    // this.setState({ auth: { token, isAuthenticated: true } });
                }
            },
        },
        user: {
            data: null,
            setUser: (data: any) => {
                this.setState((old: any) => {
                    var newModule = { ...old.user };
                    newModule.data = data;
                    return { user: newModule };
                });
            },
        },
        module: {
            name: null,
            crud: null,
            page: null,
            color: null,
            setPage: (name: string | null = null) => {
                this.setState((old: any) => {
                    var newModule = { ...old.module };
                    if (name != null) {
                        newModule.page = name;
                    }
                    return { module: newModule };
                });
            },
            setModule: (name: string | null = null, color: string | null = null, crud = null) => {
                this.setState((old: any) => {
                    var newModule = { ...old.module };
                    if (name != null) {
                        newModule.name = name;
                    }
                    if (color) {
                        newModule.color = color;
                    }
                    if (crud) {
                        newModule.crud = crud;
                    }
                    return { module: newModule };
                });
                // this.setState({ module: { name,setModule: this.state.module.setModule } });
            },
        },
    };

    componentDidMount() {
        if (this.state.auth.token != null) {
            this.state.auth.setAuth(this.state.auth.token);
        }
        onError('teste', (err: any) => {
            // alert(err);
            this.setState({ hasError: true, errorMessage: err.data })
        })


    }

    render() {
        return (
            <div>

                <Modal show={this.state.hasError} size={"md"} >
                    <Modal.Body>
                        <PrettyError error={this.state.errorMessage} />
                    </Modal.Body>
                    <Modal.Footer className="mt-4">
                        <Button onClick={() => this.setState({ hasError: false })}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
                <Router>
                    <ProtectedRouterAdmission path={'/process_admission'} component={AdmissionRoutes} />
                </Router>
                <Router>
                    <AuthContext.Provider value={this.state.auth}>
                        <ModuleContext.Provider value={this.state.module}>
                            <UserContext.Provider value={this.state.user}>
                                <SocketIoEvents user={this.state.user} />
                                <Sidebar hiddenSidebar={this.state.hiddenSidebar} />
                                <Topbar hiddenSidebar={this.state.hiddenSidebar} changeSidebar={() => { this.setState({ hiddenSidebar: !this.state.hiddenSidebar }); localStorage.setItem("hiddenSidebar", (!this.state.hiddenSidebar)?.toString()) }} />
                                {/* <Button onClick={()=> this.setState({hiddenSidebar:!this.state.hiddenSidebar})} style={{zIndex:999}}>TESTEEE</Button> */}
                                {/* <MainChat /> */}
                                <Switch>
                                    <Route exact path="/" component={LoginPage}></Route>
                                    <Route exact path="/login" component={LoginPage}></Route>

                                    <Route exact path="/setpassword" component={SetPasswordPage}></Route>
                                    <Route exact path="/resetpassword" component={ResetPassword}></Route>
                                    <ProtectedRoute hiddenSidebar={this.state.hiddenSidebar} path="/dashboard" component={DashboardRoutes}></ProtectedRoute>
                                </Switch>
                            </UserContext.Provider>
                        </ModuleContext.Provider>
                    </AuthContext.Provider>
                </Router>

            </div>
        );
    }
}

// export default App;
