import React from 'react';
import axios from '../../../../utilities/axios';
import { Notification } from 'rsuite';
import styles from "./styles/infractionCard.module.scss";
import { CloseOutline } from '@rsuite/icons';
import dayjs from 'dayjs';

interface InfractionCardState {
    totalInfraction: number;
    infractionPerMonth: number;
    currentMonth: string;
}

class InfractionCard extends React.Component<any, InfractionCardState> {
    public state: InfractionCardState = {
        totalInfraction: 0,
        infractionPerMonth: 0,
        currentMonth: `Período: ${dayjs().format('MMMM')}`,
    };

    async componentDidMount() {
        await this.handleGetCardsInformation()
    }

    handleGetCardsInformation = async () => {
        try {
            const { data } = await axios.get("/api/v1/dashboard/cards_informations");
            const { totalInfringement, totalInfringementCurrentMonth } = data;

            this.setState({
                totalInfraction: totalInfringement,
                infractionPerMonth: totalInfringementCurrentMonth
            })
        } catch (error) {
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar informações dos cards",
                duration: 3500
            })

            console.log("Erro ao buscar as informações dos cards :::::>> ", error)
        }
    }

    render() {
        return (
            <>
                <div className={styles.cardContainer}>
                    <div className={styles.lightOrangeCard}            >
                        <div style={{ marginBottom: '4px' }}><CloseOutline style={{ fontSize: '1.32rem' }} color="#000" /></div>

                        <h4 style={{ margin: '0 0', fontSize: '1.2rem', fontWeight: '600' }}>Infrações Registradas no Mês</h4>

                        <p style={{ margin: '0', fontSize: '2rem', fontWeight: 'bold' }}>{this.state.infractionPerMonth}</p>

                        <small style={{ marginTop: '4px', fontSize: '0.85rem', color: '#666' }}>{this.state.currentMonth}</small>
                    </div >

                    <div className={styles.darkOrangeCard}            >
                        <div style={{ marginBottom: '4px' }}><CloseOutline style={{ fontSize: '1.32rem' }} color="#000" /></div>

                        <h4 style={{ margin: '0 0', fontSize: '1.2rem', fontWeight: '600' }}>Infrações Acumuladas</h4>

                        <p style={{ margin: '0', fontSize: '2rem', fontWeight: 'bold' }}>{this.state.totalInfraction}</p>

                        <small style={{ marginTop: '4px', fontSize: '0.85rem', color: '#666' }}>Desde o início</small>
                    </div >

                </div>
            </>
        )
    }
}

export default InfractionCard;
