import React from "react";
import ReactApexChart from "react-apexcharts";
import axios from "../../../../utilities/axios";
import {
    Button,
    CheckTreePicker,
    FormGroup,
    Notification,
} from "rsuite";
import styles from "./styles/EmployeeErrorPerMonthChart.module.scss";
import { EmployeeErrorColumnChart } from "../../Types/EmployeeErrorColumnChartType";
import dayjs from "dayjs";
import { YearNode } from "../../Types/YearNodeType";

interface EmployeeErrorPerMonthChartState {
    errorPerMonth: EmployeeErrorColumnChart;
    selectedEmployees: string[];
    selectedYear: string[];
    loadingButton: boolean;
    selectFullData: YearNode[];
}

interface EmployeeProps {
    employee: { value: number, label: String }[]
}

class EmployeeErrorPerMonthChart extends React.Component<EmployeeProps, EmployeeErrorPerMonthChartState> {
    state: EmployeeErrorPerMonthChartState = {
        errorPerMonth: {
            series: [
                {
                    data: [],
                    name: "Mensal",
                },
            ],
            options: {
                chart: {
                    type: "bar",
                    height: 350,
                    toolbar: {
                        show: true,
                    },
                    zoom: {
                        enabled: true,
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 2,
                        horizontal: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: [],
                    tickPlacement: "on",
                },
                tooltip: {
                    y: {
                        formatter: function (val: any) {
                            return `${val} Vezes`;
                        },
                    },
                },
            },
        },
        selectedEmployees: [],
        selectedYear: ['2024'],
        loadingButton: false,
        selectFullData: [],
    };

    componentDidMount() {
        this.generateData();
    }

    generateData = (): void => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        const startYear = 2024;

        type MonthNode = { label: string; value: string };
        type YearNode = { label: string; value: string; children: MonthNode[] };

        const data: YearNode[] = [];

        for (let year = startYear; year <= currentYear; year++) {
            const yearNode: YearNode = {
                label: `${year}`,
                value: `${year}`,
                children: [],
            };

            const monthsInYear = year === currentYear ? currentMonth : 12;
            for (let month = 1; month <= monthsInYear; month++) {
                const monthLabel = new Date(year, month - 1).toLocaleString("default", {
                    month: "long",
                });

                yearNode.children.push({
                    label: monthLabel,
                    value: `${year}-${month.toString().padStart(2, "0")}`,
                });
            }

            data.push(yearNode);
        }

        this.setState({ selectFullData: data });
    };

    handleSetLoadingTrue = () => {
        this.setState({ loadingButton: true });
    };

    handleSetLoadingFalse = () => {
        this.setState({ loadingButton: false });
    };

    handleBarChartPerMonth = async () => {
        this.handleSetLoadingTrue();

        const { selectedEmployees, selectedYear } = this.state;

        // if (!selectedEmployees.length) {
        //     Notification.warning({
        //         title: "Aviso!",
        //         description: "Selecione pelo menos um funcionário para gerar o gráfico.",
        //         duration: 3500,
        //     });

        //     this.handleSetLoadingFalse();
        //     return;
        // }

        if (!selectedYear) {
            Notification.warning({
                title: "Aviso!",
                description: "Selecione um ano para gerar o gráfico.",
                duration: 3500,
            });

            this.handleSetLoadingFalse();
            return;
        }

        try {
            const response = await axios.get("/api/v1/dashboard/get_linechartpermonth_information", {
                params: {
                    employee: selectedEmployees,
                    fullPeriod: selectedYear,
                    lineChartFilterType: "employee",
                    store: [],
                    platform: []
                },
            }
            );

            console.log({ response })

            const { series, categories } = response.data;

            const formattedCategories = categories.map((date: any) => dayjs(date).format("MM/YYYY"))

            this.setState({
                errorPerMonth: {
                    ...this.state.errorPerMonth,
                    series: series,
                    options: {
                        ...this.state.errorPerMonth.options,
                        xaxis: {
                            ...this.state.errorPerMonth.options.xaxis,
                            categories: formattedCategories,
                        },
                    },
                },
            });

        } catch (error) {
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar informações para o gráfico.",
                duration: 3500,
            });
            console.error("Erro ao pegar dados para o gráfico", error);
        }

        this.handleSetLoadingFalse();
    };

    render() {
        return (
            <div className={styles.mainContainer}>
                <div className={styles.containerFilters}>
                    <FormGroup style={{
                        width: '100%'
                    }}>
                        <span>Funcionários</span>
                        <CheckTreePicker
                            data={this.props.employee}
                            placeholder="Selecione funcionários..."
                            style={{ width: "100%" }}
                            value={this.state.selectedEmployees}
                            onChange={(value) => this.setState({ selectedEmployees: value })}
                        />
                    </FormGroup>

                    <FormGroup style={{
                        width: '100%'
                    }}>
                        <span>Ano</span>
                        <CheckTreePicker
                            data={this.state.selectFullData}
                            placeholder="Período para consulta"
                            style={{ width: "100%" }}
                            value={this.state.selectedYear}
                            onChange={(value) => this.setState({ selectedYear: value })}
                            locale={{
                                ...CheckTreePicker.defaultProps?.locale,
                                checkAll: 'Ano Todo',
                                selectedValues: 'Tudo',
                            }}
                        />
                    </FormGroup>

                    <div className={styles.filterButtons}>
                        <span>ㅤ</span>
                        <Button
                            style={{ width: '250px' }}
                            appearance="primary"
                            color="blue"
                            loading={this.state.loadingButton}
                            onClick={this.handleBarChartPerMonth}>
                            Filtrar
                        </Button>
                    </div>
                </div>

                <div className={styles.lineChartContainer}>
                    <ReactApexChart
                        options={this.state.errorPerMonth.options}
                        series={this.state.errorPerMonth.series}
                        type="bar"
                        height={350}
                    />
                </div>
            </div>
        );
    }
}

export default EmployeeErrorPerMonthChart;