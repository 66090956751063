import { YearNode } from "../../../Types/YearNodeType";

export function generateYearsTreePicker() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const startYear = 2024;

    const data: YearNode[] = [];
    for (let year = startYear; year <= currentYear; year++) {
        const yearNode: YearNode = {
            label: `${year}`,
            value: `${year}`,
            children: [],
        };

        const monthsInYear = year === currentYear ? currentMonth : 12;
        for (let month = 1; month <= monthsInYear; month++) {
            yearNode.children.push({
                label: new Date(year, month - 1).toLocaleString("default", {
                    month: "long",
                }),
                value: `${year}-${month.toString().padStart(2, "0")}`,
            });
        }

        data.push(yearNode);
    }

    return data;
}