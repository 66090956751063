import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Notification, Divider, Modal, List, Button } from "rsuite";
import axios from "../../../../utilities/axios";
import styles from "./styles/detailsStorePieChart.module.scss";
import { shortenText } from "../../utils/shortenText";

interface DetailsStorePieChartProps {
    associatedOrder: any;
    store: any;
    platform: any;
    author: any;
    responsibleEmployee: any;
    violationDateRange: any;
}

interface DetailsStorePieChartState {
    options: ApexOptions;
    series: any;
    buttonLoading: boolean;
    fullData: any;
    showDetailsPieChart: boolean;
    detailsEmployeeChart: any;
    showDetailsLineChart: boolean;
    detailsErrorChart: any;
    storeCategorieData: any;
    storeColorData: any;
    storeSeriesData: any;
    filters: any;
    filteredData: any;
    showStoreErrorModal: boolean;
    modalData: any;
    modalTitle: string;
    showModalCharts: boolean;
    detailsErrorsEmployeeChart?: any;
}


export const DetailsStorePieChart = forwardRef((props: DetailsStorePieChartProps, ref) => {
    const filteredDataRef = React.useRef<any>([]);

    const [state, setState] = React.useState<DetailsStorePieChartState>({
        series: [],
        options: {
            chart: {
                width: '100%',
                height: '100%',
                type: 'pie',
                toolbar: {
                    show: true,
                    tools: {
                        download: true
                    },
                },
            },
            fill: {
                colors: []
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: -5,
                    },
                },
            },
            grid: {
                padding: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
            dataLabels: {
                //@ts-ignore
                formatter(val: any, opts: any) {
                    const name = opts.w.globals.labels[opts.seriesIndex];
                    return [name, val.toFixed(1) + '%'];
                },
            },
            legend: {
                show: false,
            },
        },
        storeCategorieData: [],
        storeSeriesData: [],
        storeColorData: [],
        fullData: [],
        detailsEmployeeChart: {
            series: [],
            options: {
                chart: {
                    width: '100%',
                    height: '100%',
                    type: 'pie',
                    toolbar: {
                        show: true,
                        tools: {
                            download: true
                        },
                    }
                },
                labels: [],
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -5,
                        },
                    },
                },
                grid: {
                    padding: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    },
                },
                dataLabels: {
                    formatter(val: any, opts: any) {
                        const name = opts.w.globals.labels[opts.seriesIndex];
                        return [name, val.toFixed(1) + '%'];
                    },
                },
                legend: {
                    show: false,
                },
            },
            detailsPeriodLineChart: {
                series: [],
                options: {
                    chart: {
                        width: '100%',
                        height: '100%',
                        type: 'pie',
                        toolbar: {
                            show: true,
                            tools: {
                                download: true
                            },
                        }
                    },
                    labels: [],
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                offset: -5,
                            },
                        },
                    },
                    grid: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                        },
                    },
                    dataLabels: {
                        formatter(val: any, opts: any) {
                            const name = opts.w.globals.labels[opts.seriesIndex];
                            return [name, val.toFixed(1) + '%'];
                        },
                    },
                    legend: {
                        show: false,
                    },
                },
            },
            detailsWeekDaysLineChart: {
                series: [15, 40, 30],
                options: {
                    chart: {
                        width: '100%',
                        height: '100%',
                        type: 'pie',
                        toolbar: {
                            show: true,
                            tools: {
                                download: true
                            },
                        }
                    },
                    labels: ["Manhã", "Tarde", "Noite"],
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                offset: -5,
                            },
                        },
                    },
                    grid: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                        },
                    },
                    dataLabels: {
                        formatter(val: any, opts: any) {
                            const name = opts.w.globals.labels[opts.seriesIndex];
                            return [name, val.toFixed(1) + '%'];
                        },
                    },
                    legend: {
                        show: false,
                    },
                },
            }
        },
        detailsErrorChart: {
            series: [],
            options: {
                chart: {
                    width: '100%',
                    height: '100%',
                    type: 'pie',
                    toolbar: {
                        show: true,
                        tools: {
                            download: true
                        },
                    },
                },
                labels: [],
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -5,
                        },
                    },
                },
                grid: {
                    padding: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    },
                },
                dataLabels: {
                    formatter(val: any, opts: any) {
                        const name = opts.w.globals.labels[opts.seriesIndex];
                        return [shortenText(name), val.toFixed(1) + '%'];
                    },
                },
                legend: {
                    show: false,
                },
            },
        },
        filteredData: [],
        modalData: [],
        showStoreErrorModal: false,
        modalTitle: "",
        showModalCharts: false,
        detailsErrorsEmployeeChart: {}
    });

    useEffect(() => {
        const loadData = async () => {
            await handleGetChartData();
        };

        loadData();
    }, []);

    useImperativeHandle(ref, () => ({
        handleGetChartData,
        applyPieChartFilter,
    }));

    const handleGetChartData = async () => {
        try {
            const graphsResponse = await axios.get("/api/v1/dashboard/graphs_informations", {
                params: {
                    filters: {
                        violationDateRange: props.violationDateRange,
                        responsibleEmployee: props.responsibleEmployee,
                        author: props.author,
                        platform: props.platform,
                        store: props.store,
                        associatedOrder: props.associatedOrder,
                    }
                }
            })

            const { pieChart } = graphsResponse.data;

            if (graphsResponse.data) {
                setState({
                    ...state,
                    series: pieChart.graphsStoreAmountInfringement || [],
                    options: {
                        ...state.options,
                        labels: pieChart.setGraphsStoreNames || [],
                        fill: {
                            colors: pieChart.setGraphsStoreColors || [],
                        },
                    },
                    storeCategorieData: pieChart.setGraphsStoreNames || [],
                    storeSeriesData: pieChart.graphsStoreAmountInfringement || [],
                    storeColorData: pieChart.setGraphsStoreColors || [],
                    fullData: pieChart.graphsInformationQuery || [],
                    filters: {
                        ...state.filters,
                        responsibleEmployee: null,
                        store: null
                    },
                    showDetailsPieChart: false,
                    showDetailsLineChart: false,
                })
            }
        } catch (error) {
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar informações para o dashboard.",
                duration: 3500
            })

            console.log("Erro ao buscar informações do dashboard ::::>> ", error)
        }
    };

    const handleDetailsErrorDataPointSelection = (event: any, chartContext: any, config: any) => {
        const selectedDataPointIndex = config.dataPointIndex;
        const labels = chartContext.w.config.labels || [];
        const selectedLabel = labels[selectedDataPointIndex];
        const currentFilteredData = filteredDataRef.current;

        // Filtrar dados para o erro selecionado
        const filteredData = currentFilteredData.filter(
            (item: any) => item?.errorName === selectedLabel
        );

        // Contar os erros por funcionário
        const data = filteredData.reduce((acc: any, item: any) => {
            const employee = item?.employee;
            if (employee) {
                acc[employee] = (acc[employee] || 0) + 1; // Incrementa a contagem
            }
            return acc;
        }, {});

        // Transformar em array de strings: "Funcionário - Número de erros"
        const formattedData = Object.entries(data).map(
            ([employee, count]) => `${employee} - ${count} vezes`
        );

        console.log(formattedData)

        setState((prevState) => ({
            ...prevState,
            modalData: formattedData, // Atualizar com dados formatados
            modalTitle: `Erro para ${selectedLabel}`,
            showStoreErrorModal: true,
        }));
    };


    const handleDataPointSelection = (event: any, chartContext: any, config: any) => {
        const selectedDataPointIndex = config.dataPointIndex;
        const labels = chartContext.w.config.labels || [];
        const label = labels[selectedDataPointIndex];

        setState((prevState) => {
            const filteredData = prevState.fullData.filter((item: any) => item?.store === label);

            filteredDataRef.current = filteredData;

            const employeeData = filteredData.reduce(
                (acc: any, item: any) => {
                    const { employee, employeeColor, errorName } = item;

                    if (acc.employeeCategories.includes(employee)) {
                        const index = acc.employeeCategories.indexOf(employee);
                        acc.employeeSeries[index] += 1;
                    } else {
                        acc.employeeCategories.push(employee);
                        acc.employeeColors.push(employeeColor);
                        acc.employeeSeries.push(1);
                    }

                    if (acc.errorCategories.includes(errorName)) {
                        const index = acc.errorCategories.indexOf(errorName);
                        acc.errorSeries[index] += 1;
                    } else {
                        acc.errorCategories.push(errorName);
                        acc.errorSeries.push(1);
                    }

                    return acc;
                },
                {
                    employeeCategories: [],
                    employeeSeries: [],
                    employeeColors: [],
                    errorCategories: [],
                    errorSeries: [],
                }
            );

            return {
                ...prevState,
                showDetailsPieChart: true,
                detailsEmployeeChart: {
                    ...prevState.detailsEmployeeChart,
                    series: employeeData.employeeSeries,
                    options: {
                        ...prevState.detailsEmployeeChart.options,
                        labels: employeeData.employeeCategories,
                        fill: {
                            colors: employeeData.employeeColors,
                        }
                    },
                },
                detailsErrorChart: {
                    series: employeeData.errorSeries,
                    options: {
                        ...prevState.detailsErrorChart.options,
                        labels: employeeData.errorCategories,
                    },
                },
                filteredData: filteredData,
                showModalCharts: true
            };
        });
    };

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            options: {
                ...prev.options,
                chart: {
                    ...prev.options.chart,
                    events: {
                        dataPointSelection: handleDataPointSelection,
                    },
                },
            },
        }));

        setState((prevState) => ({
            ...prevState,
            detailsEmployeeChart: {
                ...prevState.detailsEmployeeChart,
                options: {
                    ...prevState.detailsEmployeeChart.options,
                    chart: {
                        ...prevState.detailsEmployeeChart.options.chart,
                        events: {
                            dataPointSelection: async (event: any, chartContext: any, config: any) => {
                                const selectedIndex = config.dataPointIndex;
                                const selectedLabel = chartContext.w.config.labels[selectedIndex];

                                const currentFilteredData = filteredDataRef.current;

                                const periodCategories: string[] = [];
                                const periodSeries: number[] = [];

                                const weekDaysCategories: string[] = [];
                                const weekDaysSeries: number[] = [];

                                currentFilteredData.forEach((item: any) => {
                                    if (item?.employee === selectedLabel) {
                                        if (periodCategories.includes(item?.period)) {
                                            const index = periodCategories.indexOf(item?.period);
                                            periodSeries[index] += 1;
                                        } else {
                                            periodCategories.push(item?.period);
                                            periodSeries.push(1);
                                        }

                                        if (weekDaysCategories.includes(item?.dayWeek)) {
                                            const index = weekDaysCategories.indexOf(item?.dayWeek);
                                            weekDaysSeries[index] += 1;
                                        } else {
                                            weekDaysCategories.push(item?.dayWeek);
                                            weekDaysSeries.push(1);
                                        }
                                    }
                                });

                                const employeeErrorChart = await axios.get("/api/v1/dashboard/get_error_employee_chart_data", {
                                    params: {
                                        selectedEmployees: selectedLabel, period: props.violationDateRange
                                    }
                                });

                                setState((state) => ({
                                    ...state,
                                    detailsEmployeeChart: {
                                        ...state.detailsEmployeeChart,
                                        detailsPeriodLineChart: {
                                            ...state.detailsEmployeeChart.detailsPeriodLineChart,
                                            series: periodSeries,
                                            options: {
                                                ...state.detailsEmployeeChart.detailsPeriodLineChart.options,
                                                labels: periodCategories,
                                            },
                                        },
                                        detailsWeekDaysLineChart: {
                                            ...state.detailsEmployeeChart.detailsWeekDaysLineChart,
                                            series: weekDaysSeries,
                                            options: {
                                                ...state.detailsEmployeeChart.detailsWeekDaysLineChart.options,
                                                labels: weekDaysCategories,
                                            },
                                        },
                                    },
                                    showDetailsLineChart: true,
                                    detailsErrorsEmployeeChart: {
                                        series: employeeErrorChart.data.series,
                                        options: {
                                            ...state.options,
                                            labels: employeeErrorChart.data.categories,
                                        }
                                    }
                                }));
                            },
                        },
                    },
                },
            },
        }));

        setState((prevState) => ({
            ...prevState,
            detailsErrorChart: {
                ...prevState.detailsErrorChart,
                options: {
                    ...prevState.detailsErrorChart.options,
                    chart: {
                        ...prevState.detailsErrorChart.options.chart,
                        events: {
                            dataPointSelection: handleDetailsErrorDataPointSelection,
                        },
                    },
                },
            },
        }));


    }, [state.fullData]);

    const handleCloseModal = () => {
        setState({
            ...state,
            showStoreErrorModal: false
        })
    }

    const handleCloseModalCharts = () => {
        setState({
            ...state,
            showModalCharts: false
        })
    }

    const applyPieChartFilter = (selectedStoreNames: string[]) => {
        const { storeSeriesData, storeColorData, storeCategorieData } = state;
        const newSeries: number[] = [];
        const newColors: string[] = [];

        selectedStoreNames.forEach(store => {
            const storeIndex = storeCategorieData.indexOf(store)
            if (storeIndex !== -1) {
                newSeries.push(storeSeriesData[storeIndex])
                newColors.push(storeColorData[storeIndex])
            }
        })

        setState({
            ...state,
            series: newSeries,
            options: {
                ...state.options,
                labels: selectedStoreNames,
                fill: {
                    colors: newColors,
                },
            },
        });
    };

    return (
        <>
            <div className={styles.mainContainer}>
                <div>
                    <div id="chart">
                        <ReactApexChart
                            options={state.options}
                            series={state.series}
                            type="pie"
                            height={500}
                        />
                    </div>
                    <div id="html-dist"></div>
                </div>

                <div style={{ marginLeft: '-40vw' }}>

                    {/* <Modal
                    backdrop={true}
                    show={state.showModalCharts}
                    onHide={handleCloseModalCharts}
                    //@ts-ignore
                    size="full" // Ocupa toda a tela
                >
                    <Modal.Body> */}
                    {state.showDetailsPieChart && (
                        <>
                            <Divider>Detalhes - Gráfico por Loja</Divider>

                            <div style={{ display: 'flex', gap: '1rem', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div>
                                        <div id="chart">
                                            <ReactApexChart
                                                options={state.detailsEmployeeChart.options}
                                                series={state.detailsEmployeeChart.series}
                                                type="pie"
                                                width={500}
                                            />
                                        </div>
                                        <div id="html-dist"></div>
                                    </div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div>
                                        <div id="chart">
                                            <ReactApexChart
                                                options={state.detailsErrorChart.options}
                                                series={state.detailsErrorChart.series}
                                                type="pie"
                                                width={500}
                                            />
                                        </div>
                                        <div id="html-dist"></div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {state.showDetailsLineChart && (
                        <>
                            <Divider>Detalhes - Gráfico por Funcionário</Divider>

                            <div style={{ display: 'flex', gap: '1rem', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div>
                                        <div id="chart">
                                            <ReactApexChart
                                                options={state.detailsEmployeeChart.detailsPeriodLineChart.options}
                                                series={state.detailsEmployeeChart.detailsPeriodLineChart.series}
                                                type="pie"
                                                width={500}
                                            />
                                        </div>
                                        <div id="html-dist"></div>
                                    </div>
                                </div>

                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div>
                                        <div id="chart">
                                            <ReactApexChart
                                                options={state.detailsEmployeeChart.detailsWeekDaysLineChart.options}
                                                series={state.detailsEmployeeChart.detailsWeekDaysLineChart.series}
                                                type="pie"
                                                width={500}
                                            />
                                        </div>
                                        <div id="html-dist"></div>
                                    </div>
                                </div>
                            </div>

                            {Object.keys(state.detailsErrorsEmployeeChart).length > 0 && (
                                <div style={{ display: 'flex', gap: '1rem', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div>
                                            <div id="chart">
                                                <ReactApexChart
                                                    options={state.detailsErrorsEmployeeChart.options}
                                                    series={state.detailsErrorsEmployeeChart.series}
                                                    type="pie"
                                                    width={500}
                                                />
                                            </div>
                                            <div id="html-dist"></div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {/* </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleCloseModalCharts} appearance="subtle">
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal> */}
                </div>
            </div >

            <Modal backdrop={true} show={state.showStoreErrorModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title>{state.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <List>
                        {state.modalData.map((item: any, index: number) => (
                            <List.Item key={index} index={index}>
                                {item}
                            </List.Item>
                        ))}
                    </List>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} appearance="subtle">
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
});
