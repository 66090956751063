import React from "react";
import ReactApexChart from "react-apexcharts";
import styles from "./styles/lineChartPerWeek.module.scss";
import axios from "../../../../utilities/axios";
import { Button, Checkbox, CheckboxGroup, CheckPicker, FormGroup, Notification, Radio, RadioGroup } from "rsuite";
import { ApexOptions } from "apexcharts";

const lineChartFilterOptions: string[] = ['1', '2', '3', '4']

interface LineChartPerWeekProps {
    stores: { label: string; value: number }[];
    platforms: { label: string; value: number }[];
}

interface LineChartPerWeekState {
    series: {
        name: string;
        data: number[];
    }[];
    options: ApexOptions;
    filters: {
        weekSelect: {
            indeterminate: boolean,
            checkAll: boolean,
            value: string[],
        },
        employee: number[],
        store: number[],
        platform: number[],
        lineChartFilterType: string,
    },
    loadingButton: boolean;
}

class LineChartPerWeek extends React.Component<LineChartPerWeekProps, LineChartPerWeekState> {
    public state: LineChartPerWeekState = {
        series: [],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            colors: [],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
            },
        },
        filters: {
            weekSelect: {
                indeterminate: true,
                checkAll: true,
                value: ['1', '2', '3', '4'],
            },
            employee: [],
            store: [],
            platform: [],
            lineChartFilterType: ""
        },
        loadingButton: false
    }

    async componentDidMount() {
        await this.handleGetChartData;
    }

    handleGetChartData = async () => {
        this.setState({
            loadingButton: true
        })

        try {
            const { filters } = this.state;

            if (!filters?.lineChartFilterType) {
                Notification.warning({
                    title: "Aviso!",
                    description: "Selecione Loja ou Plataforma para buscar as informações",
                    duration: 3500
                })

                this.setState({
                    loadingButton: false
                })

                return;
            }

            if (filters.weekSelect?.value.length === 0) {
                Notification.warning({
                    title: "Aviso!",
                    description: "Selecione o período para fazer a busca",
                    duration: 3500
                })

                this.setState({
                    loadingButton: false
                })

                return;
            }

            const sortedWeekSelect = filters.weekSelect?.value.sort((a: any, b: any) => Number(a) - Number(b))

            const lineChartResponse = await axios.get("/api/v1/dashboard/get_linechart_information", {
                params: {
                    weekSelect: sortedWeekSelect ? sortedWeekSelect : [],
                    lineChartFilterType: filters?.lineChartFilterType ? filters?.lineChartFilterType : "",
                    employee: filters?.employee ? filters?.employee : [],
                    store: filters?.store ? filters?.store : [],
                    platform: filters?.platform ? filters?.platform : [],
                }
            })

            const { series, categories, colors } = lineChartResponse.data;

            this.setState({
                series: series,
                options: {
                    ...this.state.options,
                    colors: colors,
                    xaxis: {
                        categories: categories,
                    }
                }
            })

        } catch (error) {
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar informação para o gráfico de linha",
                duration: 3500
            })
            console.log("Erro ao pegar dados para o gráfico de linha", error)
        }

        this.setState({
            loadingButton: false
        })
    }


    handleCheckAll = (value: any, checked: any) => {
        const nextValue = checked ? lineChartFilterOptions : [];
        this.setState((prevState) => ({
            filters: {
                ...prevState.filters,
                weekSelect: {
                    value: nextValue,
                    indeterminate: false,
                    checkAll: checked,
                },
            },
        }));
    };

    handleChange = (value: any) => {
        this.setState((prevState) => ({
            filters: {
                ...prevState.filters,
                weekSelect: {
                    value,
                    indeterminate: value.length > 0 && value.length < lineChartFilterOptions.length,
                    checkAll: value.length === lineChartFilterOptions.length,
                },
            },
        }));
    };

    render() {
        return (
            <>
                <div className={styles.lineChart}>
                    <div className={styles.lineChartFilters}>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <FormGroup controlId="radioList">
                                <span>‎ </span>
                                <RadioGroup
                                    name="radioList"
                                    inline
                                    style={{ display: 'flex' }}
                                    value={this.state.filters.lineChartFilterType}
                                    onChange={(value) =>
                                        this.setState((prevState) => ({
                                            filters: {
                                                ...prevState.filters,
                                                lineChartFilterType: value,
                                            },
                                        }))
                                    }>
                                    <Radio value="store">Loja</Radio>
                                    <Radio value="platform">Plataforma</Radio>
                                </RadioGroup>
                            </FormGroup>

                            <div style={{ width: '550px' }}>
                                {this.state.filters.lineChartFilterType === 'employee' && (
                                    <div className={styles.filterDiv}>
                                        <span>Funcionários</span>
                                        <CheckPicker
                                            data={[]}
                                            placeholder="Funcionários para filtro..."
                                            style={{ width: '550px' }}
                                            value={this.state.filters.employee}
                                            onChange={(value) =>
                                                this.setState((prevState) => ({
                                                    filters: {
                                                        ...prevState.filters,
                                                        employee: value,
                                                    },

                                                }))
                                            }
                                        />
                                        <span className={styles.informationText}>
                                            *Se nenhum filtro for aplicado, todos os registros serão incluídos
                                        </span>
                                    </div>
                                )}

                                {this.state.filters.lineChartFilterType === 'store' && (
                                    <div className={styles.filterDiv}>
                                        <span>Lojas</span>
                                        <CheckPicker
                                            data={this.props.stores}
                                            placeholder="Lojas para filtro..."
                                            style={{ width: '550px' }}
                                            value={this.state.filters.store}
                                            onChange={(value) =>
                                                this.setState((prevState) => ({
                                                    filters: {
                                                        ...prevState.filters,
                                                        store: value,
                                                    },
                                                }))
                                            }
                                        />
                                        <span className={styles.informationText}>
                                            *Se nenhum filtro for aplicado, todos os registros serão incluídos
                                        </span>
                                    </div>
                                )}

                                {this.state.filters.lineChartFilterType === 'platform' && (
                                    <div className={styles.filterDiv}>
                                        <span>Plataformas</span>
                                        <CheckPicker
                                            data={this.props.platforms}
                                            placeholder="Plataformas para filtro..."
                                            style={{ width: '550px' }}
                                            value={this.state.filters.platform}
                                            onChange={(value) =>
                                                this.setState((prevState) => ({
                                                    filters: {
                                                        ...prevState.filters,
                                                        platform: value,
                                                    },
                                                }))
                                            }
                                        />
                                        <span className={styles.informationText}>
                                            *Se nenhum filtro for aplicado, todos os registros serão incluídos
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={styles.filterDiv}>
                            <span>‎ </span>
                            <div className={styles.filterButtons}>
                                <Button
                                    appearance="primary"
                                    color="blue"
                                    style={{ width: '100%' }}
                                    loading={this.state.loadingButton}
                                    onClick={this.handleGetChartData}
                                >
                                    Filtrar
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.lineChartContainer}>
                        <div style={{ width: '100%' }}>
                            <div id="chart">
                                <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
                            </div>
                            <div id="html-dist"></div>
                        </div>
                        <div className={styles.checkboxContainer}>
                            <Checkbox
                                indeterminate={this.state.filters.weekSelect.indeterminate}
                                checked={this.state.filters.weekSelect.checkAll}
                                onChange={this.handleCheckAll}
                            >
                                Marcar tudo
                            </Checkbox>
                            <CheckboxGroup
                                name="checkboxList"
                                value={this.state.filters.weekSelect.value}
                                onChange={this.handleChange}
                            >
                                <Checkbox value="1">Semana 1</Checkbox>
                                <Checkbox value="2">Semana 2</Checkbox>
                                <Checkbox value="3">Semana 3</Checkbox>
                                <Checkbox value="4">Semana 4</Checkbox>
                            </CheckboxGroup>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default LineChartPerWeek;