import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Modal, List, Button, Notification } from "rsuite";
import axios from "../../../../utilities/axios";

interface PieChartPerPeriodWeekProps {
    selectedStore: number;
    type: string;
    violationDateRange: string[];
    author: number;
    associatedOrder: string;
}

interface PieChartPerPeriodWeekState {
    options: ApexOptions;
    series: number[];
    isOpenModal: boolean;
    modalTitle: string;
    buttonLoading: boolean;
    modalData: any[];
    fullData: any[];
}

export const PieChartPerPeriodWeek = forwardRef((props: PieChartPerPeriodWeekProps, ref) => {
    const [state, setState] = React.useState<PieChartPerPeriodWeekState>({
        series: [],
        options: {
            chart: {
                width: '100%',
                height: '100%',
                type: 'pie',
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                    },
                },
            },
            grid: {
                padding: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
            labels: [],
            dataLabels: {
                //@ts-ignore
                formatter(val: any, opts: any) {
                    const name = opts.w.globals.labels[opts.seriesIndex];
                    return [name + "\n" + val.toFixed(1) + '%']
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
            legend: {
                show: false,
            },
        },
        fullData: [],
        modalData: [],
        modalTitle: "",
        isOpenModal: false,
        buttonLoading: false,
    });

    useEffect(() => {
        const loadData = async () => {
            await handleGetChartData();
        };

        loadData();
    }, []);

    useImperativeHandle(ref, () => ({
        handleGetChartData,
    }));

    const handleGetChartData = async () => {
        setState((prev) => ({ ...prev, buttonLoading: true }));

        try {
            const { selectedStore, type, violationDateRange, author, associatedOrder } = props;
            const [startDate, endDate] = violationDateRange.map((date) => new Date(date).toISOString());

            const response = await axios.get("/api/v1/dashboard/get_store_error_chart_data", {
                params: { selectedStore, type, startDate, endDate, author, associatedOrder },
            });

            if (response.data?.data) {
                setState((prev) => ({
                    ...prev,
                    series: response.data.series,
                    options: { ...prev.options, labels: response.data.categories },
                    fullData: response.data.storeErrorData,
                }));
            } else {
                setState((prev) => ({ ...prev, series: [], options: { ...prev.options, labels: [] } }));
            }
        } catch (error) {
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar informações do gráfico.",
            });
        }

        setState((prev) => ({ ...prev, buttonLoading: false }));
    };

    const handleOpenModal = (data: any[], title: string) => {
        setState((prev) => ({
            ...prev,
            isOpenModal: true,
            modalTitle: title,
            modalData: data,
        }));
    };

    const handleCloseModal = () => {
        setState((prev) => ({ ...prev, isOpenModal: false }));
    };

    const handleDataPointSelection = (event: any, chartContext: any, config: any) => {
        const selectedDataPointIndex = config.dataPointIndex;
        const labels = state.options.labels || [];
        const label = labels[selectedDataPointIndex];
        const periods = ["MANHÃ", "TARDE", "NOITE"];
        const { fullData } = state;

        let columnName = "";
        if (periods.includes(label)) {
            columnName = "infringement_control_period";
        } else {
            columnName = "infringement_control_day_of_the_week";
        }

        // Filtrar os dados pelo período ou dia da semana
        const filteredData = fullData.filter((item: any) => item[columnName] === label);

        console.log({ filteredData })

        // Agrupar os dados por funcionário e erro
        const summarizedData = filteredData.reduce((acc: any, item: any) => {
            const employee = item.user_name;
            const error = item.error_name;

            const key = `${employee} - ${error}`;
            acc[key] = (acc[key] || 0) + 1; // Incrementar a contagem para essa combinação
            return acc;
        }, {});

        console.log({ summarizedData })

        // Transformar o resumo em um array de strings
        const formattedData = Object.entries(summarizedData).map(
            ([key, count]) => `${key}: ${count} pedidos`
        );

        console.log({ formattedData })

        // Abrir o modal com os dados formatados
        handleOpenModal(formattedData, `Detalhes para ${label}`);
    };


    useEffect(() => {
        setState((prev) => ({
            ...prev,
            options: {
                ...prev.options,
                chart: {
                    ...prev.options.chart,
                    events: {
                        dataPointSelection: handleDataPointSelection,
                    },
                },
            },
        }));
    }, [state.fullData]);

    return (
        <>
            <ReactApexChart options={state.options} series={state.series} type="pie" height={500} />

            <Modal backdrop={true} show={state.isOpenModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title>{state.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <List>
                        {state.modalData.map((item: any, index: number) => (
                            <List.Item key={index} index={index}>
                                {item}
                            </List.Item>
                        ))}
                    </List>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} appearance="subtle">
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
});
