import React from "react";
import ReactApexChart from "react-apexcharts";
import styles from "./styles/lineChartPerMonth.module.scss";
import axios from "../../../../utilities/axios";
import { Button, CheckPicker, CheckTreePicker, FormGroup, Notification, Radio, RadioGroup } from "rsuite";
import { ApexOptions } from "apexcharts";
import { YearNode } from "../../Types/YearNodeType";
import { generateYearsTreePicker } from "./utils/generateYearsTreePicker";

interface LineChartPerMonthProps {
    stores: { label: string; value: number }[];
    platforms: { label: string; value: number }[];
    showFilters: boolean;
}

interface LineChartPerMonthState {
    series: {
        name: string;
        data: number[];
    }[];
    options: ApexOptions;
    filters: {
        fullPeriod: string[],
        employee: number[],
        store: number[],
        platform: number[],
        lineChartFilterType: string,
    },
    loadingButton: boolean;
    yearsSelectPicker: YearNode[];
}

class LineChartPerMonth extends React.Component<LineChartPerMonthProps, LineChartPerMonthState> {
    public state: LineChartPerMonthState = {
        series: [],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            colors: [],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
            },
        },
        filters: {
            fullPeriod: ['2024'],
            employee: [],
            store: [],
            platform: [],
            lineChartFilterType: ""
        },
        loadingButton: false,
        yearsSelectPicker: []
    }

    async componentDidMount() {
        const treePickerData = generateYearsTreePicker()

        this.setState({
            yearsSelectPicker: treePickerData
        })
    }

    handleGetChartData = async () => {
        this.setState({
            loadingButton: true
        })

        try {
            const { filters } = this.state;
            if (!filters?.lineChartFilterType) {
                Notification.warning({
                    title: "Aviso!",
                    description: "Selecione Loja ou Plataforma para buscar as informações",
                    duration: 3500
                })

                this.setState({
                    loadingButton: false
                })

                return;
            }

            if (filters.fullPeriod.length === 0) {
                Notification.warning({
                    title: "Aviso!",
                    description: "Selecione um período para buscar as informações",
                    duration: 3500
                })

                this.setState({
                    loadingButton: false
                })

                return;
            }

            const { fullPeriod, employee, lineChartFilterType, platform, store } = filters;

            const lineChartPerMonthResponse = await axios.get("/api/v1/dashboard/get_linechartpermonth_information", {
                params: {
                    fullPeriod: fullPeriod ? fullPeriod : [],
                    lineChartFilterType: lineChartFilterType ? lineChartFilterType : "",
                    employee: employee ? employee : [],
                    store: store ? store : [],
                    platform: platform ? platform : [],
                }
            })

            const { series, categories, colors } = lineChartPerMonthResponse.data;

            const formattedCategories = categories.map((date: any) => {
                const [year, month] = date.split('-');
                return `${month}/${year}`;
            });

            this.setState({
                series: series,
                options: {
                    ...this.state.options,
                    colors: colors,
                    xaxis: {
                        categories: formattedCategories,
                    }
                }
            })

        } catch (error) {
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar informação para o gráfico de linha",
                duration: 3500
            })
            console.log("Erro ao pegar dados para o gráfico de linha", error)
        }

        this.setState({
            loadingButton: false
        })
    }

    render() {
        return (
            <>
                <div className={styles.lineChart}>
                    {!this.props.showFilters && (
                        <>
                            <div className={styles.lineChartMonthFilters}>
                                <div style={{ display: 'flex', gap: '1rem' }}>
                                    <FormGroup controlId="radioList">
                                        <span>‎ </span>
                                        <RadioGroup
                                            name="radioList"
                                            inline
                                            style={{ display: 'flex' }}
                                            value={this.state.filters.lineChartFilterType}
                                            onChange={(value) =>
                                                this.setState((prevState) => ({
                                                    filters: {
                                                        ...prevState.filters,
                                                        lineChartFilterType: value,
                                                    },
                                                }))
                                            }>
                                            <Radio value="store">Loja</Radio>
                                            <Radio value="platform">Plataforma</Radio>
                                        </RadioGroup>
                                    </FormGroup>

                                    <div style={{ width: '550px' }}>
                                        {this.state.filters.lineChartFilterType === 'store' && (
                                            <div className={styles.filterDiv}>
                                                <span>Lojas</span>
                                                <CheckPicker
                                                    data={this.props.stores}
                                                    placeholder="Lojas para filtro..."
                                                    style={{ width: '550px' }}
                                                    value={this.state.filters.store}
                                                    onChange={(value) =>
                                                        this.setState((prevState) => ({
                                                            filters: {
                                                                ...prevState.filters,
                                                                store: value,
                                                            },
                                                        }))
                                                    }
                                                />
                                                <span className={styles.informationText}>
                                                    *Se nenhum filtro for aplicado, todos os registros serão incluídos
                                                </span>
                                            </div>
                                        )}

                                        {this.state.filters.lineChartFilterType === 'platform' && (
                                            <div className={styles.filterDiv}>
                                                <span>Plataformas</span>
                                                <CheckPicker
                                                    data={this.props.platforms}
                                                    placeholder="Plataformas para filtro..."
                                                    style={{ width: '550px' }}
                                                    value={this.state.filters.platform}
                                                    onChange={(value) =>
                                                        this.setState((prevState) => ({
                                                            filters: {
                                                                ...prevState.filters,
                                                                platform: value,
                                                            },
                                                        }))
                                                    }
                                                />
                                                <span className={styles.informationText}>
                                                    *Se nenhum filtro for aplicado, todos os registros serão incluídos
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className={styles.filterDiv}>
                                    <div className={styles.filterButtons}>
                                        <Button
                                            appearance="primary"
                                            color="blue"
                                            style={{ width: '100%' }}
                                            loading={this.state.loadingButton}
                                            onClick={this.handleGetChartData}
                                        >
                                            Filtrar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <CheckTreePicker
                                    data={this.state.yearsSelectPicker}
                                    style={{ width: '100%' }}
                                    placeholder="Período para consulta..."
                                    value={this.state.filters.fullPeriod}
                                    onChange={(value) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                fullPeriod: value
                                            }
                                        })
                                    }}
                                    locale={{
                                        ...CheckTreePicker.defaultProps?.locale,
                                        checkAll: 'Ano Todo',
                                        selectedValues: 'Tudo',
                                    }}
                                />
                            </div>
                        </>
                    )}
                    <div className={styles.lineChartContainer}>
                        <div style={{ width: '100%' }}>
                            <div id="chart">
                                <ReactApexChart
                                    options={this.state.options}
                                    series={this.state.series}
                                    type="line"
                                    height={350}
                                />
                            </div>
                            <div id="html-dist"></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default LineChartPerMonth;