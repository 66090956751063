import React from "react";
import ReactApexChart from "react-apexcharts";
import styles from "./styles/employeeErrorPerWeekChart.module.scss";
import { EmployeeErrorColumnChart } from "../../Types/EmployeeErrorColumnChartType";
import axios from "../../../../utilities/axios";
import { Button, CheckPicker, Notification } from "rsuite";

interface EmployeeErrorPerWeekChartProps {
    employees: any;
}
interface EmployeeErrorPerWeekChartState {
    employeeErrorColumnChart: EmployeeErrorColumnChart;
    filters: {
        selectedEmployees: number[]
    };
    buttonLoading: boolean;
}

class EmployeeErrorPerWeekChart extends React.Component<EmployeeErrorPerWeekChartProps, EmployeeErrorPerWeekChartState> {
    public state: EmployeeErrorPerWeekChartState = {
        employeeErrorColumnChart: {
            series: [],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                title: {
                    text: 'Erros por Funcionário',
                    align: 'left'
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '90%',
                        borderRadius: 5,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
                    tickPlacement: 'on'
                },
                // fill: {
                //     colors: [],
                //     opacity: 1
                // },
                tooltip: {
                    y: {
                        formatter: function (val: any) {
                            return `${val} Vezes`;
                        }
                    }
                }
            },
        },
        filters: {
            selectedEmployees: [],
        },
        buttonLoading: false,
    }

    async componentDidMount() {
        await this.handleGetChartData()
    }

    handleChangeLoadingButton = async () => {
        await this.setState({
            ...this.state,
            buttonLoading: !this.state.buttonLoading
        })
    }

    handleGetChartData = async () => {
        await this.handleChangeLoadingButton()

        try {
            const { selectedEmployees } = this.state.filters;

            const response = await axios.get("/api/v1/dashboard/employee_week_chart", {
                params: {
                    selectedEmployees
                }
            })

            this.setState({
                employeeErrorColumnChart: {
                    ...this.state.employeeErrorColumnChart,
                    series: response.data.formattedResult,
                    options: {
                        ...this.state.employeeErrorColumnChart.options,
                        // fill: {
                        //     colors: false ? response.data.colors : [],
                        //     opacity: 1
                        // },
                    }
                }
            })

        } catch (error) {
            console.log("Erro ao buscar informações para o gráfico de funcionário por semana ::::>> ", error)
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar informações para o gráfico de funcionário por semana!",
                duration: 3500
            })

            await this.handleChangeLoadingButton()
        }

        await this.handleChangeLoadingButton()
    }

    render() {
        return (
            <>
                <div className={styles.container}>
                    <div className={styles.filters}>
                        <CheckPicker
                            data={this.props.employees}
                            placeholder="Selecione os funcionários (se nenhum for selecionado, todos serão incluídos)"
                            style={{ width: "100%" }}
                            value={this.state.filters.selectedEmployees}
                            onChange={(value) => {
                                this.setState({
                                    ...this.state,
                                    filters: {
                                        selectedEmployees: value
                                    }
                                })
                            }}
                        />
                        <Button
                            appearance="primary"
                            color="blue"
                            style={{ width: "200px" }}
                            onClick={this.handleGetChartData}
                            loading={this.state.buttonLoading}
                        >Filtrar</Button>
                    </div>

                    <div>
                        <ReactApexChart
                            options={this.state.employeeErrorColumnChart.options}
                            series={this.state.employeeErrorColumnChart.series}
                            type="bar"
                            height={350}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default EmployeeErrorPerWeekChart;